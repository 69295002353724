import React, { useEffect, useState, useContext } from 'react';
import { css } from '@emotion/react';
import tw from 'twin.macro';
import Layout from '../../../components/site/layout';
import { Shapes } from '../../../components/site/shapes';
import { ContainerStyled } from '../../../components/site/styles';
import { CompanyContext } from '../../../context/company.context';
import { LangContext } from '../../../context/lang.context';

interface IMarkDownPages {
  html: string;
  fileAbsolutePath: string;
}

const TermsPage = ({ pageContext }) => {
  const markDownPages: IMarkDownPages[] = pageContext.company.markDownPages;
  const { getPublicURL } = useContext(CompanyContext);
  const { lang } = useContext(LangContext);
  /**
   * @hack to refresh
   */
  const [platform, setPlatform] = useState('ssr');

  const getHTML = () => {
    return markDownPages.find(
      (mdpage: IMarkDownPages) => mdpage.fileAbsolutePath.includes(`/terms/${lang}.md`) && mdpage.html
    );
  };

  useEffect(() => {
    setPlatform('browser');
  }, []);

  return (
    <Layout topbarSticky key={platform}>
      <div className="relative md:overflow-hidden text-white pt-20">
        <img
          src={getPublicURL(`/theme/assets/images/site/portrait-bg-mobile-short.svg`)}
          className="md:hidden absolute top-0 left-0 w-full"
          style={{ minHeight: 560, maxHeight: 640 }}
        />
        <Shapes shiftUp />

        <ContainerStyled className="relative">
          <section
            className="text-gray-800 mx-2 md:mx-32 mb-16 text-sm leading-6"
            css={css`
              h1 {
                ${tw`text-center font-normal text-primary-contrast text-4xl leading-tight md:leading-normal md:text-5xl h-32 mt-16`}
              }
              h3 {
                font-weight: 700;
              }
              p {
                margin-bottom: 1.5rem;
              }
              blockquote {
                ${tw`p-5 bg-primary text-primary-contrast rounded-md mb-6`}
              }
            `}
            dangerouslySetInnerHTML={{ __html: `${getHTML()?.html}` }}
          />
        </ContainerStyled>
      </div>
    </Layout>
  );
};

export default TermsPage;
